//
// Alkacon website SCSS.
// 
// Backgrounds styles.
//

/* Parallax effect
---------------------------------- */

.parallax-img {
    background-position: center top;
    background-repeat: no-repeat;
    z-index: 1;
    position: relative;
}

.no-touch .translucent-bg,
.no-touch .parallax {
    background-attachment: fixed !important;
}
.translucent-bg,
.parallax {
    -webkit-background-size: cover!important;
    -moz-background-size: cover!important;
    -o-background-size: cover!important;
    background-size: cover!important;
}
.parallax {
    background-position: 50% 0;
    background-repeat: no-repeat;
    z-index: 1;
    position: relative;
    padding: 50px 0;
}