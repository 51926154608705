.ap-linksequence ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.ap-linksequence ul.ap-bullets {
	list-style-type: disc;
	padding: inherit;
}
        
.ap-linksequence-boxed li {
}

.ap-linksequence-boxed li a {
	position: relative;
	display: block;
	padding: 10px 15px;
	margin-bottom: -1px;
	border: 1px solid $grey-light;
	text-decoration: none;
}

.ap-linksequence li a span {
	margin-right: 8px;
}

// head / foot formatted links

.ap-linksequence-hf .links {
    padding: 8px 0 0 0;
    position: relative;
    z-index: 12;
}

.ap-linksequence-hf .links ul {
    margin: 0;
    padding-left: 0;
}

.ap-linksequence-hf .links ul li {
	display: inline;
    list-style: outside none none;
    padding-bottom: 15px;
    position: relative;
}

.ap-linksequence-hf .links ul li.divider {
    border-right: 1px solid $grey;
    font-family: Tahoma;
    font-size: 8px;
    margin: 0 9px 0 5px;
    padding: 0;
    position: relative;
    top: -1px;
}

.ap-linksequence-hf .links ul li > a, .ap-linksequence-hf .links ul li > a:hover {
    color: $grey-dark;
    font-size: 11px;
    text-transform: uppercase;
}