/* Empty container markers.
----------------------------------------------------------------------------- */

$oc-col-area:          #ffc9d2 !default; // darken($red-lighter, 5%)
$oc-col-segment:       #ffe3e7 !default; // $red-lighter
$oc-col-grid:          #ffdac7 !default; // mix(darken($red-lighter, 5%), $orange-lighter, 40%)),
$oc-col-row:           #ffe5bf !default; // $orange-lighter
$oc-col-element:       #e8f3f8 !default; // "light steel blue"
$oc-col-special1:      #effab4 !default; // "light green"

$oc-col-developer:     #b31b34 !default; // $red
$oc-col-editor:        #ffa826 !default; // $orange
$oc-col-author:        #144382 !default; // $blue
$oc-col-detail:        #1bb39a !default; // $cyan
$oc-col-detailonly:    #1bb39a !default; // $cyan
$oc-col-special2:      #ff264a !default; // $red-light

$oc-col-text:          #ffffff !default; // white
$oc-col-border:        #a8adb4 !default; // $grey

// OpenCms roles and their matching colors  
$oc-container-types:
(
 (area, $oc-col-area),        
 (segment, $oc-col-segment),        
 (grid, $oc-col-grid),         
 (row, $oc-col-row),         
 (element, $oc-col-element),         
 (special, $oc-col-special1),
) !default;

$oc-container-labels:
(
 (developer, $oc-col-developer),         
 (editor, $oc-col-editor),         
 (author, $oc-col-author),         
 (detail, $oc-col-detail),
 (detailonly, $oc-col-detailonly),         
 (special, $oc-col-special2),
) !default; 

%oc-container-type {
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;   
    overflow: hidden; 
    white-space: normal;
    border-radius: 10px;  
    -webkit-border-radius: 10px;
    
    h1 {
        font-size: 22px;
        font-weight: 400;
        margin: 5px;
    }  
    p {
        margin: 5px;
        font-size: 14px;        
    } 
}

%oc-container-label {
    display: inline;
    font-size: 12px;
    font-weight: 400;
    position: relative;
    top: -5px;
    padding: 2px 5px;
    margin-left: 5px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    white-space: nowrap;  
    color: $oc-col-text;             
} 

@each $type-name, $bg-color in $oc-container-types {
    .oc-container-#{""+$type-name} {
        @extend %oc-container-type;        
        background-color: rgba($bg-color, 0.75);      
    }
}

@each $role-name, $label-color in $oc-container-labels {      
    .oc-label-#{""+$role-name} {
        @extend %oc-container-label;  
        background-color: $label-color;        
    }  
}

.oc-modelinfo {
    border: 2px solid $oc-col-border;  
    padding: 4px;
    margin-bottom: 10px;
}

.oc-modelinfo .alert {
    margin: 0 0 10px 0;   
}

.oc-container-text {
    color: darken($oc-col-border, 15%);
    line-height: 1.2;
}
