//
// Alkacon website SCSS.
// 
// Styles imported from the "idea" template without modifications (except for colors). 
//

/* 1 Typography
----------------------------------------------------------------------------- */
html {
    height: 100%;
}
body {
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    line-height: 1.50;
    color: $text-dark;
    background-color: #ffffff;
}
body.full-height, body.full-height .page-wrapper {
    background-color: #fafafa;
    min-height: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    color: $headline-text;
    line-height: 1.2;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: $headline-text;
}
h1 {
    font-size: 38px;
    font-weight: 700;
}
h2 {
    font-size: 28px;
    margin-bottom: 15px;
}
h3 {
    font-size: 22px;
}
h4 {
    font-size: 18px;
    font-weight: 700;
}
h5 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
}
h6 {
    font-weight: 700;
}

/* Medium devices (desktops, tablets, 992px and up) */
@media (min-width:992px) {
    .title {
        margin-top: 0;
    }
}

.sidebar .title {
    margin-bottom: 15px;
    margin-top: 20px;
    position: relative;
}
.page-header {
    margin-top: 20px;
    padding-top: 20px;
}
p {
    margin-bottom: 20px;
}
a {
    color: $link-text;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
a:hover {
    color: $link-text-hover;
}
a:focus,
a:active {
    outline: none;
}
.no-trans * {
    -webkit-transition: none !important;
       -moz-transition: none !important;
        -ms-transition: none !important;
         -o-transition: none !important;
            transition: none !important;
}
img {
    display: block;
    max-width: 100%;
    height: auto;
}
blockquote {
    border-left: none;
    display: block;
    margin: 20px auto 20px;
    font-size: 16px;
    position: relative;
    padding: 10px 10px 10px 0px;
    margin-left: 40px;
}
blockquote:after {
    content: "\f10e";
    width: 25px;
    height: 25px;
    line-height: 36px;
    font-size: 36px;
    /* font-family: 'PT Serif', serif; font was 64px */
    font-family: "FontAwesome";
    position: absolute;
    top: 12px;
    left: -45px;
}
.blockquote:before {
    /* font-family: 'PT Serif', serif; */
    font-family: "FontAwesome";
    content: "\f10e";
    font-size: 40px;
}
blockquote.inline {
    padding: 0;
}
blockquote.inline p {
    width: 60%;
    display: inline-block;
    margin: 0;
}
blockquote.inline footer {
    width: 37%;
    display: inline-block;
    padding-left: 5px;
}
blockquote.inline:after {
    top: 0;
}
mark,
.mark {
    background-color: $mark-bg;
    color: $mark-text;
}
pre {
    padding: 20px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
}
.text-muted {
    color: #999999;
}
.text-default {
    color: $red;
}
.well {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    background-color: #f3f3f3;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .05);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .05);
}
.icons-page i {
    color: #333;
    font-size: 20px;
}

hr.dark,
#footer .footer hr {
	border-color: #333333;
}

#footer.light hr {
	border-color: #dddddd;
}

/* Lists
---------------------------------- */
ul {
    list-style: square;
}
.list-icons,
.list {
    list-style: none;
    padding: 0;
}
.list-icons li,
.list li {
    padding: 5px 0;
}
.list-icons li i {
    width: 25px;
    text-align: center;
}
.list li .vertical-divider {
    padding-right: 15px;
    position: relative;
}
.list li .vertical-divider:after {
    position: absolute;
    content: "";
    height: 60%;
    width: 1px;
    background-color: #CECECE;
    right: 9px;
    top: 5px;
}
.list-inline {
    margin-top: 9px;
    margin-bottom: 8px;
}

/* Tables
---------------------------------- */
.table:not(.table-bordered) {
    border-bottom: 2px solid #f3f3f3;
}
.table > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 1px solid $red;
    background-color: $red;
    color: #fff;
}
.table-bordered {
    border-color: $red;
}
tbody {
    background-color: #ffffff;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
    padding: 8px 15px;
    border-top: 1px solid #f3f3f3;
}
.table-striped tbody {
    background-color: #fafafa;
}
.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
    background-color: #f1f1f1;
}
.table-striped.table > tbody > tr > td {
    border-color: #e8e8e8;
}
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
    border-color: #f3f3f3;
}
.dl-horizontal dd {
    margin-bottom: 10px;
}
.dark .table:not(.table-bordered) {
    border-bottom: 2px solid #515151;
}
.dark .table > thead > tr > th {
    border-bottom: 1px solid #27272a;
    background-color: #27272a;
    color: #cdcdcd;
}
.dark tbody {
    background-color: transparent;
}
.dark .table > thead > tr > th,
.dark .table > tbody > tr > th,
.dark .table > tfoot > tr > th,
.dark .table > thead > tr > td,
.dark .table > tbody > tr > td,
.dark .table > tfoot > tr > td {
    border-top: 1px solid #515151;
}
.dark .table-hover > tbody > tr:hover {
    background-color: #27272a;
}
/* Small devices (tablets, 768px and up) */
@media (min-width:768px) {
    .dl-horizontal dd {
        margin-left: 150px;
    }
    .dl-horizontal dt {
        max-width: 120px;
    }
}

/* 2 Layout
----------------------------------------------------------------------------- */
.page-wrapper {
    background-color: #ffffff;
}
.header-top {
    background-color: $header-top-bg;
    border-bottom: 1px solid $header-top-border;
    -webkit-box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.03) inset;
    box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.03) inset;
}
.header-top.dark {
    
}
.header-top.banner {
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: none;
}
.header {
    padding: 20px 0;
    border-bottom: 1px solid $gray-light;
    background-color: #fff;
}
.header.header-small {
    padding: 10px 0;
}
.banner {
    background-color: #f1f1f1;
    border-bottom: 1px solid #eaeaea;
}
.page-intro {
    padding: 10px 0;
    -webkit-box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.06) inset;
    box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.06) inset;
    background-color: #fafafa;
    border-bottom: 1px solid #dadada;
}
.banner + .page-intro {
    margin-top: -56px !important;
    position: relative;
    background-color: rgba(250, 250, 250, 0.9);
    z-index: 2;
}
.page-top {
    padding: 40px 0;
}
.main-container {
    padding: 40px 0;
}
.full-height .main-container {
    padding-top: 80px;
}
.section {
    padding: 25px 0;
}
.footer-top.light-gray-bg {
	border-top: 1px solid #f1f1f1;
}
.footer {
    padding: 30px 0;
    background-color: $footer-bg;
    border-top: 1px solid $footer-border-top;
    border-bottom: 1px solid $footer-border-bottom;
}
.subfooter {
    padding: 25px 0;
    color: $subfooter-text;
    background-color: $subfooter-bg;
}

.block {
    padding-bottom: 40px;
}
.sidebar .block:first-child {
    margin-top: 2px;
}

.slider-banner-container .dark-translucent-bg {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0!important;
	left: 0!important;
	margin: 0!important;
	bottom: 0;
}

@media (min-width: 768px) { 
	:not(.fixed-header-on) .header.transparent {
		position: absolute;
		width: 100%;
		z-index: 30;
		background-color: rgba(255,255,255,0.3);
		border-color: transparent;
	}
	:not(.fixed-header-on) .dark.header.transparent {
		background-color: rgba(0,0,0,0.3);
	}	
}

/* Fixed navigation
---------------------------------- */
.fixed-header-on .header.fixed {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1006;
    opacity: 0;
    filter: alpha(opacity=0);
    border-bottom: 1px solid $fixed-header-border;
    padding: 7px 0;
    background-color: $fixed-header-bg;
    -webkit-transition:border-bottom 1s;
    transition:border-bottom 1s;
}
.fixed-header-on.boxed .header.fixed {
    left: auto;
}
.fixed-header-on .header .logo {
    -webkit-transform: scale(0.80);
    -moz-transform: scale(0.80);
    -ms-transform: scale(0.80);
    -o-transform: scale(0.80);
    transform: scale(0.80);
    margin: 0 0 -4px 0;
    display: none;
}
.fixed-header-on .header .site-slogan {
    display: none;
}
.fixed-header-on .header .navbar.navbar-default {
    margin: 0;
}
.fixed-header-on .header.fixed.animated {
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
}

/* Small devices (tablets, 768px and up) */
@media (min-width:768px) {
    .fixed-header-on.boxed .header.fixed {
        width: 750px;
    }
}

/* Medium devices (desktops, tablets, 992px and up) */
@media (min-width:992px) {
    .fixed-header-on .header .logo {
        display: block;
    }
    .fixed-header-on.boxed .header.fixed {
        width: 970px;
    }
}

/* Large devices (Large desktops 1200px and up) */
@media (min-width:1200px) {
    .fixed-header-on.boxed .header.fixed {
        width: 1170px;
    }
}

/* Small devices (tablets, phones less than 767px) */
@media (max-width:767px) {
    /* Fixed header position */
    .header.fixed {
        position: relative !important;
        top: auto !important;
        border-bottom: none;
        padding: 10px 0 0 0;
    }
}

/* Miscellaneous
---------------------------------- */
.space {
    padding-top: 20px;
    padding-bottom: 20px;
}
.space-top {
    padding-top: 20px;
}
.space-bottom {
    padding-bottom: 20px;
}
.margin-clear {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.padding-top-clear {
    padding-top: 0 !important;
}
.padding-bottom-clear {
    padding-bottom: 0 !important;
}
.padding-clear {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}
.border-clear {
    border: none !important;
}
.circle {
	-webkit-border-radius: 100%; 
	-moz-border-radius: 100%; 
	border-radius: 100%;
}
.separator {
    display: block;
    width: 60px;
    height: 5px;
    margin: 15px auto 15px;
    background-color: #cccccc;
    position: relative;
    border: 1px solid #cccccc;
}
.separator:after {
    width: 30px;
    height: 5px;
    background: #333333;
    position: absolute;
    top: -1px;
    left: -1px;
    content: "";
}
.separator-2 {
    display: block;
    width: 30px;
    height: 5px;
    margin: 15px 0 15px;
    background-color: #cccccc;
    position: relative;
}
.separator-2.right {
	margin-left: auto;
}
.sidebar .separator {
    width: 30px;
    height: 5px;
    margin: 15px 0 20px;
    background-color: #666;
    border-color: #666;
}
.sidebar .separator:after {
    content: "";
    background-color: transparent;
}
.dark-bg .separator-2 {
    background-color: #999999;
}
.default-bg .separator-2 {
    background-color: #ebebeb;
}
.object-non-visible {
    opacity: 0;
    filter: alpha(opacity=0);
}
.object-visible,
.touch .object-non-visible {
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
}

/* Targeting only Firefox for smoothest animations */
@-moz-document url-prefix() {
    .object-visible,
    .touch .object-non-visible {
        -webkit-transition: opacity 0.6s ease-in-out;
        -moz-transition: opacity 0.6s ease-in-out;
        -o-transition: opacity 0.6s ease-in-out;
        -ms-transition: opacity 0.6s ease-in-out;
        transition: opacity 0.6s ease-in-out;
    }
}

.vertical-divider-left,
.vertical-divider-left-lg {
    border-left: 1px solid #eeeeee;
    padding-left: 50px;
    margin-left: 20px;
}
.vertical-divider-right,
.vertical-divider-right-lg {
    border-right: 1px solid #eeeeee;
    padding-right: 50px;
    margin-right: 20px;
}
.grid span {
    padding: 15px 5px;
    background-color: #fafafa;
    border: 1px solid #f3f3f3;
    display: block;
    text-align: center;
    margin-bottom: 10px;
}

/* Medium devices (desktops, tablets, 992px and up) */
@media (min-width:992px) {
    .margin-top-clear {
        margin-top: 0 !important;
    }
    .margin-bottom-clear {
        margin-bottom: 0 !important;
    }
}

/* Large and Medium devices (desktops, tablets less than 1199px) */
@media (max-width:1199px) {
    /* miscellaneous */
    .portfolio-item.vertical-divider-left-lg,
    .side.vertical-divider-left-lg {
        border-left: none;
        padding-left: 0px;
        margin-left: 0px;
    }
    .portfolio-item.vertical-divider-right-lg,
    .side.vertical-divider-right-lg {
        border-right: none;
        padding-right: 0px;
        margin-right: 0px;
    }
}

/* Medium devices (desktops, tablets less than 991px) */
@media (max-width:991px) {
    /* miscellaneous */
    .portfolio-item.vertical-divider-left,
    .side.vertical-divider-left {
        border-left: none;
        padding-left: 0px;
        margin-left: 0px;
    }
    .portfolio-item.vertical-divider-right,
    .side.vertical-divider-right {
        border-right: none;
        padding-right: 0px;
        margin-right: 0px;
    }
}



/* 3 Sections
----------------------------------------------------------------------------- */
/* Footer
---------------------------------- */
.footer {
    font-size: 14px;
    color: $footer-text;
}
.footer h1,
.footer h2,
.footer h3,
.footer h4,
.footer h5,
.footer h6 {
    color: $footer-text;
}
.footer h2 {
    font-size: 24px;
    margin-bottom: 30px;
}

/* Subfooter
---------------------------------- */
.subfooter {
    font-size: 14px;
}
.subfooter p {
    margin: 0;
}

/* Small devices (tablets, 768px and up) */
@media (min-width:768px) {
    /* subfooter */
    .subfooter {
        text-align: center;
    }
}

/* 5 Template Components
----------------------------------------------------------------------------- */

/* Links
---------------------------------- */
.link {
    padding-right: 23px;
    position: relative;
    display: inline-block;
    text-align: right;
}
.link:hover { 
    text-decoration: none;
}
.link:hover span {
    text-decoration: underline;
}
.link:after {
    content: "\f178";
    font-family: "FontAwesome";
    position: absolute;
    right: 0;
    top: 2px;
}

/* Breadcrumb
---------------------------------- */
.breadcrumb {
    background-color: transparent;
    margin-bottom: 0;
    font-size: 13px;
    padding: 8px 0;
}

/* Nav pills
---------------------------------- */
.nav-pills > li > a {
    border-radius: 0;
    padding: 10px 15px;
    border: 1px solid transparent;
    color: $text-dark;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 700;
}
.nav-pills.nav-stacked > li + li {
    margin-left: 0;
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus,
.nav-pills > li > a:hover,
.nav-pills > li > a:focus {
    border: 1px solid #f3f3f3;
    color: $red;
    background-color: #fafafa;
}
.nav-pills.nav-stacked > li > a:after {
    font-family: "FontAwesome";
    content: "\f105";
    font-size: 14px;
    font-weight: 700;
    position: absolute;
    right: 15px;
    top: 9px;
    color: #d1d1d1;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.nav-pills > li.active > a:after {
    color: $red;
}
.nav-pills > li.active > a:hover:after,
.nav-pills > li.active > a:focus:after,
.nav-pills > li > a:hover:after,
.nav-pills > li > a:focus:after {
    color: $red;
    right: 10px;
}
.footer-content .nav-pills > li > a {
    padding: 4px 0px;
    border-color: transparent;
    color: $footer-link-text;
    font-weight: 400;
}
.footer-content .nav-pills > li:last-child > a {
    border-bottom: none;
}
.footer-content .nav-pills > li.active > a,
.footer-content .nav-pills > li.active > a:focus,
.footer-content .nav-pills > li > a:focus {
    color: $footer-link-text;
    background-color: transparent;
    border-color: transparent;
}
.footer-content .nav-pills > li.active > a:hover,
.footer-content .nav-pills > li > a:hover {
    color: $footer-link-text-hover;
    background-color: transparent;
    border-color: transparent;
}
.footer-content .nav-pills.nav-stacked > li > a:after {
    top: 3px;
}
.footer-content .nav-pills.nav-stacked > li > a:after,
.footer-content .nav-pills > li.active > a:after,
.footer-content .nav-pills > li.active > a:hover:after,
.footer-content .nav-pills > li.active > a:focus:after,
.footer-content .nav-pills > li > a:hover:after,
.footer-content .nav-pills > li > a:focus:after {
    color: $footer-link-text;
}

.nav-pills > .active > a > .badge {
	color: #cd3c2e;
	background-color: #fff;
	border-color: #cd3c2e;	
}

/* Process pills */
.process .nav-pills > li + li {
    margin-left: 60px;
    position: relative;
}
.process .nav-pills > li + li:after {
    font-family: "FontAwesome";
    content: "\f101";
    position: absolute;
    top: 10px;
    left: -35px;
    width: 10px;
    background-color: #fff;
}

/* Images and Images overlay
---------------------------------- */
.fixed-image {
	background-position: center center;
	background-repeat: no-repeat;
	height: 400px;
}
.no-touch .fixed-image {
	background-attachment: fixed;
}
.fixed-image {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
.overlay-container {
	position: relative;
	display: block;
	overflow: hidden;
}
.overlay {
	position: absolute;
	top: 0;
	bottom: -1px;
	left: 0;
	right: -1px;
	background-color: rgba(241, 241, 241, 0.85);
	overflow: hidden;
	opacity: 0;
	filter: alpha(opacity=0);
	-webkit-transform: scale(0.8);
	transform: scale(0.8);
	-webkit-transition: all linear 0.2s;
	-moz-transition: all linear 0.2s;
	-ms-transition: all linear 0.2s;
	-o-transition: all linear 0.2s;
	transition: all linear 0.2s;
}
.overlay a {
	display: inline-block;
	width: 50px;
	height: 50px;
	font-size: 26px;
	color: $red;
	background-color: #ffffff;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -25px;
	margin-left: -25px;
}
.overlay span {
	position: absolute;
	display: block;
	bottom: 10px;
	text-align: center;
	width: 100%;
	color: #000000;
	font-style: italic;
}
.overlay-links {
	display: block;
	margin: 0 auto;
	position: absolute;
	top: 50%;
	margin-top: -25px;
	left: 50%;
	margin-left: -50px;
	font-size: 0;
	opacity: 0;
	filter: alpha(opacity=0);
	-webkit-transform: scale(0.4);
	transform: scale(0.4);
	-webkit-transition: all 0.25s linear 0.05s;
	-moz-transition: all 0.25s linear 0.05s;
	-ms-transition: all 0.25s linear 0.05s;
	-o-transition: all 0.25s linear 0.05s;
	transition: all 0.25s linear 0.05s;
}
.overlay-links a {
	position: static;
	margin-top: 0;
	margin-left: 0;
}
.overlay-links a + a {
	left: 50px;
	color: #ffffff;
	background-color: $red;
}
.overlay a:hover {
	color: #ffffff;
	background-color: #333333;
	text-decoration: none;
}
.overlay i {
	line-height: 50px;
}
.box-style-1 .overlay i {
	font-size: 26px;
	margin: 0;
}
a.overlay {
	background-color: rgba(255, 255, 255, 0.9);
	cursor: pointer;
}
a.overlay:hover {
	text-decoration: none;
}
.listing-item a.overlay {
	-webkit-transition: linear 0.25s;
	-o-transition: linear 0.25s;
	transition: linear 0.25s;
	background-color: rgba(255, 255, 255, 0.7);
	-webkit-transform: scale(1);
	transform: scale(1);
}
.listing-item a.overlay:hover {
	text-decoration: none;
}
a.overlay i {
	position: absolute;
	left: 50%;
	top: 50%;
	font-size: 40px;
	line-height: 42px;
	color: $red;
	margin-top: -22px;
	margin-left: -18px;
	text-align: center;
}
a.overlay.small i {
	font-size: 18px;
	line-height: 1;
	margin-top: -8px;
	margin-left: -8px;
}
.overlay .social-links {
	margin: 0;
	position: absolute;
	bottom: 10px;
	width: 100%;
	text-align: center;
}
.overlay .social-links li {
	margin: 0 auto;
}
.overlay .social-links li a {
	margin: 2px;
	width: 40px;
	height: 40px;
	font-size: 18px;
	position: static;
}
.overlay .social-links li a:hover {
	background-color: #fff;
	border-color: #fff;
	text-decoration: none;
}
.overlay .social-links li a i {
	line-height: 38px;
	font-size: 24px;
	margin: 0;
}
.overlay-container:hover .overlay,
.overlay-container:hover .overlay-links {
	opacity: 1;
	filter: alpha(opacity=100);
	-webkit-transform: scale(1);
	transform: scale(1);
}

/* 6 Navigations
----------------------------------------------------------------------------- */
/* first level menu */
.navbar.navbar-default {
    border-radius: 0;
    margin: 1px 0 0;
    background-color: $navmenu-main-bg;
    border-color: #f1f1f1;
}
.header-small .navbar.navbar-default {
    margin: 6px 0 5px;
}

/* first level menu items */
.navbar-default .navbar-nav > li > a {
    color: $text-dark;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    padding: 12px 20px 12px 19px;
    z-index: 102;
    border: 1px solid transparent;
    bottom: -1px;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    -ms-transition: none;
    transition: none;
}
.navbar-default .navbar-nav > li > a:hover {
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

/* first level active menu items on hover/focus */
.header .navbar-default .navbar-nav > .active > a,
.header .navbar-default .navbar-nav > .active > a:focus {   
    color: $navmenu-activeitem-text;
    background-color: transparent;
}
.header .navbar-default .navbar-nav > .active > a:hover {
    background-color: $navmenu-bg;
    border-color: $navmenu-border;
    border-bottom-color: $navmenu-border-sep;
}

.header .navbar-default .navbar-nav > .active.dropdown > a:before {
    color: #e1e1e1;
}

/* first level active menu items when opened */
.header .main-navigation .navbar-nav > .active.open > a,
.header .main-navigation .navbar-nav > .active.open > a:hover,
.header .main-navigation .navbar-nav > .active.open > a:focus {
    color: $navmenu-lvl-1-text-hover;
}

.header .main-navigation .navbar-nav > .open > a,
.header .main-navigation .navbar-nav > .open > a:hover,
.header .main-navigation .navbar-nav > .open > a:focus {
    background-color: $navmenu-bg;
    color: $navmenu-lvl-1-text-hover;
    border: 1px solid $navmenu-border;
    border-bottom: 1px solid $navmenu-bg;
    -webkit-box-shadow: -1px -1px 0px rgba(0, 0, 0, 0.03);
    box-shadow: -1px -1px 0px rgba(0, 0, 0, 0.03);
}

/* first level active menu items when clicked */
.header .main-navigation .navbar-nav > .open > a:active,
.header .main-navigation .navbar-nav > .open.active > a:active {
    background-color: #ffffff;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: transparent;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}
.main-navigation .navbar-nav > .open > a:active + ul,
.main-navigation .navbar-nav > .open.active > a:active + ul {
    display: none;
}
.navbar-nav{
/* arrows for second level menu parent menu items */
.dropdown-menu    .dropdown>a:before {
        font-family: 'FontAwesome';
        content: "\f107";
        position: absolute;
        left: auto;
        top: 15px;
        right: 6px;
        line-height: 1;
        color: $navmenu-text;
    } 
        
/* second level menu */
.dropdown-menu {
    min-width: 220px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    padding: 0;
    margin: 0;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
    background-color: $navmenu-bg;
    border: 1px solid $navmenu-border;
    z-index: 101;
}
}

/* second level menu items */
.header .dropdown-menu > li > a {
    padding: 12px 20px;
    font-size: 15px;
    color: $navmenu-text;
    border-bottom: 1px solid $navmenu-border-sep;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    -ms-transition: none;
    transition: none;
}

.main-navigation .dropdown-menu > li > a { 
	position: relative;
}

/* second level menu items on focus/hover and when opened */
.header .dropdown-menu > li > a:hover,
.header .dropdown-menu > li > a:focus,
.header .nav .open > a,
.header .nav .open > a:hover,
.header .nav .open > a:focus,
.header .dropdown-menu > .active > a,
.header .dropdown-menu > .active > a:hover,
.header .dropdown-menu > .active > a:focus,
.header .dropdown-menu .menu > .active > a,
.header .dropdown-menu .menu > .active > a:hover,
.header .dropdown-menu .menu > .active > a:focus {
    background-color: $navmenu-bg-hover;
    color: $navmenu-lvl-1-text-hover;
    border-color: #f1f1f1;
    border-bottom-color: $navmenu-border-sep;
}

.header .dropdown-menu > li:last-child > a {
    border: none;
}

/* third level menu */    
.header .dropdown-menu .dropdown-menu {
    top: -1px;
    left: 100% !important;
    padding-top: 0;
    margin-left: 1px;
    border-left: none;
    -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.08);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.08);
    border-top: 1px solid #f1f1f1;
}

/* mega menu */
.mega-menu.dropdown {
    position: static;
}
.mega-menu .dropdown-menu {
    left: 0;
    top: 96%;
    width: 100%;
    padding: 20px 30px;
}

/* mega menu menu items */
.header h1,
.header h2,
.header h3,
.header h4,
.header h5,
.header h6,
.header p {
    color: $navmenu-text;
}
.header .divider {
    background-color: $navmenu-border-sep;
}
.mega-menu .menu {
    list-style: none;
    padding: 0;
    margin: 0;
}
.mega-menu h4.title {
    margin: 10px 0 5px;
    font-size: 14px;
    text-transform: uppercase;
}
.header .mega-menu .menu > li > a {
    padding: 5px 0;
    font-size: 15px;
    color: $navmenu-text;
    border-bottom: 1px solid $navmenu-border-sep;
    display: block;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    -ms-transition: none;
    transition: none;
	position: relative;
}
.mega-menu .menu > li > a i,
.dropdown-menu > li > a i {
    padding-right: 5px;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.mega-menu .menu > li:last-child > a {
    border-bottom: none!important;
}
.mega-menu .menu > li > a:hover i,
.dropdown-menu > li > a:hover i {
    padding-left: 5px;
}

/* mega menu menu items on focus/hover and when opened */
.header .mega-menu .menu > li > a:hover,
.header .mega-menu .menu > li > a:focus {
    background-color: $navmenu-bg-hover;
    color: #ffffff;
    border-color: #f1f1f1;
    border-bottom-color: $navmenu-border-sep;
    text-decoration: none;
}

.header .navbar-default .navbar-nav > .active.dropdown > a:before {
    color: #ffffff;
}
.header .navbar-default .navbar-nav > .dropdown.open > a:before {
    color: $text-dark;
}
.header .dropdown .dropdown>a:before {
    content: "\f105";
    right: 10px;
    top: 15px;
    margin-left: 0;
    visibility: visible;
    color: #e1e1e1;
}
.header .dropdown .dropdown.open>a:before,
.transparent.header:not(.dark) .dropdown .dropdown.open>a:before  {
    color: #e1e1e1;
}


/* mobile menu toggle button */
.navbar-default .navbar-toggle {
    border-color: #ccc;
}

/* mobile menu toggle button on hover/focus */
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background-color: #fafafa;
    border-color: $red;
}
.navbar-default .navbar-toggle .icon-bar {
    background-color: #999;
}
.navbar-default .navbar-toggle:hover .icon-bar,
.navbar-default .navbar-toggle:focus .icon-bar {
    background-color: $red;
}


/*Dark navigation second level menu items*/

.header-top .dropdown-menu {
	border-radius: 0;
    border: 1px solid $header-top-border;
    border-top-color: $header-top-border;
    background-color: $header-top-bg;
}
.header-top .dropdown-menu .divider { 
    background-color: $header-top-bg;
}


/* Dark navigation mobile menu toggle button */
.header .navbar-default .navbar-toggle {
    border-color: $text-dark;
}

/* Dark navigation mobile menu toggle button on hover/focus */
.header .navbar-default .navbar-toggle:hover,
.header .navbar-default .navbar-toggle:focus {
    background-color: $navmenu-activeitem-text;
    border-color: $navmenu-main-bg;
    color: $navmenu-main-bg;
}
.header .navbar-default .navbar-toggle .icon-bar {
    background-color: $text-dark;
}
.header .navbar-default .navbar-toggle:hover .icon-bar,
.header .navbar-default .navbar-toggle:focus .icon-bar {
    background-color: $navmenu-border-sep;
}

/* dropdown animations */
.animated.main-navigation .open .dropdown-menu,
#offcanvas.animated .open .dropdown-menu,
.open .dropdown-animation {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInDownSmall;
    animation-name: fadeInDownSmall;
}
.open .dropdown-animation {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
}
.animated.main-navigation .dropdown-menu .open .dropdown-menu,
#offcanvas.animated .dropdown-menu .open .dropdown-menu {
    -webkit-animation-name: fadeInLeftSmall;
    animation-name: fadeInLeftSmall;
}
.animated.main-navigation .dropdown-menu .open .dropdown-menu.to-left,
#offcanvas.animated.offcanvas-right .dropdown-menu .open .dropdown-menu {
	-webkit-animation-name: fadeInRightSmall;
	animation-name: fadeInRightSmall;
}
/* Small devices (tablets, 768px and up) */
@media (min-width:768px) {
    .main-navigation .navbar-nav.navbar-right:last-child {
        margin-right: 0;
    }
    
    /* first level menu */
    .navbar.navbar-default,
    .dark.header .navbar.navbar-default {
        background-color: transparent;
        border-color: transparent;
    }
    
    /* layout fixes */
    .main-navigation .container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
    .navbar-collapse {
        padding-right: 0px;
        padding-left: 0px;
    }
    .main-navigation .navbar-right .dropdown-menu {
        right: auto;
        left: 0;
    }
    .main-navigation .navbar-right > li:last-child > .dropdown-menu {
        right: 0;
        left: auto;
    }
}

/* Medium desktop devices and tablets */
@media (min-width:768px) and (max-width:1199px) {
    .main-navigation .container-fluid {
        padding-right: 0px;
        padding-left: 0px;
    }
}

/* Medium devices (tablets landscape to portrait) */
@media (min-width:768px) and (max-width:991px) {
    .main-navigation .navbar-nav {
        float: left !important;
    }
}

/* Medium devices (desktops, tablets, 992px and up) */
@media (min-width:992px) {
    .col-md-3 + .col-md-9 .mega-menu .dropdown-menu {
        left: -243px;
        width: 940px;
    }
}

/* Medium desktop devices */
@media (min-width:992px) and (max-width:1199px) {
    .navbar-default .navbar-nav > li > a {
        padding-right: 19px;
    }
}

/* Large devices (Large desktops 1200px and up) */
@media (min-width:1200px) {
    /* first level menu item */
    .main-navigation .navbar-nav > li {
        margin: 0 0 0 15px;
    }
    .main-navigation .navbar-nav > li:first-child {
        margin: 0;
    }
    .col-md-3 + .col-md-9 .mega-menu .dropdown-menu {
        left: -293px;
        width: 1140px;
    }
}

/* Small devices (tablets, phones less than 767px) */
@media (max-width:767px) {
    /* Mobile menu
    ------------------------------ */
    
        /* layout fixes */
    .main-navigation {
        padding-right: 0;
        padding-left: 0;
    }

    .navbar-default .navbar-nav > li > a {
        color: $text-dark;
    }
    
    .header .navbar-default {
        background-color: $navmenu-main-bg;
    }

    /* arrows for parent menu items */
    .dropdown>a:before {
        font-family: 'FontAwesome';
        content: "\f105";
        position: absolute;
        left: auto;
        top: 15px;
        right: 6px;
        line-height: 1;
        color: #e1e1e1;
    }    
    .transparent.header:not(.dark) .dropdown>a:before { 
	    color: #666666;
    }

    /* active items */
    .navbar-default .navbar-nav .open .dropdown-menu > .active > a,
    .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
        color: $red;
        background-color: transparent;
    }
    
    /* first level items */
    .navbar-default .navbar-nav > li > a {
        border-bottom: 1px solid #f1f1f1;
    }
    .subfooter .navbar-default .navbar-nav > li > a {
        color: $subfooter-text;
        border-bottom: 1px solid $subfooter-divider;
    }
    .navbar-default .navbar-nav > li:last-child > a {
        border-bottom: 1px solid transparent;
    }
    
    /* first level items when opened */
    .main-navigation .navbar-nav > .open > a,
    .main-navigation .navbar-nav > .open > a:hover,
    .main-navigation .navbar-nav > .open > a:focus {
        border-color: transparent!important;
        -moz-box-shadow: none;
        -o-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    
    /* first level items hover and focus states */
    .navbar-default .navbar-nav > li > a:hover,
    .navbar-default .navbar-nav > li > a:focus,
    .navbar-default .navbar-nav > .active > a:hover,
    .navbar-default .navbar-nav > .active > a:focus {
        color: $red;
        background-color: #fff;
    }
    
    /* second level menu */
    .navbar-default .navbar-nav .open .dropdown-menu {
        background-color: #ffffff;
    }
    
    /* second level items */
    .navbar-default .navbar-nav .open .dropdown-menu > li > a,
    .navbar-default .navbar-nav .open .dropdown-menu .dropdown-header {
        padding: 12px 15px 12px 40px;
        color: $text-dark;
        border-color: #fafafa;
    }
    
    /* second level items on hover/focus */
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
        color: $red;
    }
    
    /* third level items */
    .navbar-nav .open .dropdown-menu .dropdown-menu > li > a {
        padding-left: 60px;
    }
    
    /* four level items */
    .navbar-nav .open .dropdown-menu .dropdown-menu .dropdown-menu > li > a {
        padding-left: 80px;
    }
    
    /* Arrows for parent menu items */
    .dropdown>a:before {
        right: 32px;
    }
    .dropdown .dropdown>a:before {
        right: 36px;
    }

    /*Dark mobile menu
    ------------------------------ */
    .header .navbar-default .navbar-collapse, 
    .header .navbar-default .navbar-form {
        border-color: #1f1f1f;
    }
    /* active items */
    .header .navbar-default .navbar-nav .open .dropdown-menu > .active > a,
    .header .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
    .header .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
        background-color: $navmenu-bg;
    }
    /* first level items */
    .header .navbar-default .navbar-nav > li > a {
        border-bottom: 1px solid $navmenu-border-sep;
    }

    /* first level active items */
    .header .navbar-default .navbar-nav > .active > a, 
    .header .navbar-default .navbar-nav > .active > a:focus {
        color: #ffffff;
        background-color: $navmenu-bg-hover;
    }

    /* first level items hover and focus states */
    .header .navbar-default .navbar-nav > li > a:hover,
    .header .navbar-default .navbar-nav > li > a:focus,
    .header .navbar-default .navbar-nav > .active > a:hover,
    .header .navbar-default .navbar-nav > .active > a:focus, 
    .header .main-navigation .navbar-nav > .open > a:hover, 
    .header .main-navigation .navbar-nav > .open > a:focus {
        color: #ffffff;
        background-color: $navmenu-bg-hover;
    }

    /* first level items when opened */ 
    .header .main-navigation .navbar-nav > .open > a {
        color: #ffffff;
        background-color: $navmenu-bg;
    }

    /* second level menu */
    .header .navbar-default .navbar-nav .open .dropdown-menu {
        background-color: $navmenu-bg;
        border: none;
    }
    /* second level items */
    .header .navbar-default .navbar-nav .open .dropdown-menu > li > a,
    .header .navbar-default .navbar-nav .open .dropdown-menu .dropdown-header {
        color: darken($text-light, 10%);
        border-color: $navmenu-border-sep;
    }
    
    /* second level items on hover/focus */
    .header .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
    .header .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
        color: #ffffff;
        background-color: $navmenu-bg-hover;
    }
    .header .navbar-default .navbar-nav > .dropdown.open > a:before {
        color: #ffffff;
    }
}

/* Header top navigation
---------------------------------- */
.header-top .navbar.navbar-default {
    margin: 0;
    min-height: 30px;
}
.header-top .navbar-default .navbar-nav > li > a {
    color: #999999;
    padding: 4px 20px;
}
.header-top .navbar-default .navbar-nav > li {
    border-right: 1px solid #999999;
}
.header-top .navbar-default .navbar-nav > li:last-child {
    border-right: none;
}

/* Subfooter navigation
---------------------------------- */
.subfooter .navbar {
    margin: 0;
    min-height: 0;
    background: transparent;
    border-color: transparent;
}
.subfooter .navbar-default .nav > li > a {
    padding: 10px 10px;
    text-transform: none;
    line-height: 1;
    font-size: 14px;
    font-weight: 400;
}
.subfooter .navbar-default .nav li:last-child a,
.light .subfooter .navbar-default .nav > li:last-child > a {
    border: none;
}
.subfooter .navbar-default .nav > li > a:hover,
.subfooter .navbar-default .nav > .active > a:hover,
.subfooter .navbar-default .nav > .active > a:focus {
    background-color: transparent;
    text-decoration: underline;
    color: $link-text-hover;
}

/* Small devices (tablets, 768px and up) */
@media (min-width:768px) {
    .subfooter .navbar-nav {
        float: none;
        margin: 15px 0;
        text-align: center;
    }
    .subfooter .navbar-nav > li {
        float: none;
        padding: 5px 0;
    }
    .subfooter .navbar-default .nav > li > a {
        padding: 0px 10px;
    }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width:992px) {
    .subfooter {
        text-align: left;
    }
    .subfooter .navbar-nav {
        float: right;
        margin: 0;
        text-align: left;
    }
    .subfooter .navbar-nav > li {
        float: left;
        padding: 0;
        margin-top: 4px;
    }
    .subfooter .navbar-default .nav > li > a {
        color: $subfooter-text;
        border-right: 1px solid $subfooter-divider;
    }
}

/* Small devices (tablets, phones less than 767px) */
@media (max-width:767px) {
    /* subfooter nav */
    .subfooter .navbar-default .navbar-toggle:hover,
    .subfooter .navbar-default .navbar-toggle:focus {
        background-color: transparent;
        border-color: #888;
    }
    .light .subfooter .navbar-default .navbar-collapse {
        border-color: #ddd;
    }
    .subfooter .navbar-default .navbar-collapse {
        border-color: #000000;
    }
}

/* 7 Blocks/Widgets
----------------------------------------------------------------------------- */
/* Logo
---------------------------------- */
/*
.logo img {
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}
.logo img:hover {
    opacity: 0.85;
    filter: alpha(opacity=85);
}
*/
.main-container .logo img {
    margin: 0 auto;
}
.header-small .logo img {
    max-height: 40px;
    margin: 2px 0 -4px 0;
}
.text-center .logo img {
    margin: 0 auto;
}
.site-slogan {
    font-size: 13px;
    font-style: italic;
    color: #999999;
}

.main-container .site-slogan {
    color: inherit;
    text-align: center;
    margin-bottom: 20px;
}
.logo-footer {
    margin: 25px 0 20px;
}

/* Medium devices (desktops, tablets less than 991px) */
@media (max-width:991px) {
    .logo img,
    .header-small .logo img {
        margin: 0 auto;
    }
    .site-slogan {
        text-align: center;
    }
}

/* Image Boxes
---------------------------------- */
.image-box {
	background-color: #fafafa;
	border: 1px solid #f3f3f3;
}
.grid-space-0 .image-box {
	border: none;
}
.image-box h3.title {
	margin-top: 0;
}
.image-box .btn {
	white-space: normal;
	border-color: transparent;
}
.image-box .btn-block {
	margin: 0;
}
.image-box-body {
	padding: 20px 15px 15px;
}


/* Social icons block
---------------------------------- */
.social-links {
    list-style: none;
    font-size: 0;
    padding: 0;
    margin: 40px 0 20px 0;
}
.social-links li {
    display: inline-block;
    margin: 0 2px 2px 0;
}
.social-links li a {
    width: 45px;
    height: 45px;
    display: block;
    text-align: center;
    font-size: 20px;
    background: $social-links-border;
    border: 1px solid $social-links-border;
    color: $social-links-text;
}
.social-links.large li a {
    width: 60px;
    height: 60px;
    font-size: 24px;
}
.social-links.radius-5px li a {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}
.social-links.circle li a {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}
.social-links.rounded li a {
    -webkit-border-radius: 20%;
    -moz-border-radius: 20%;
    border-radius: 20%;
}
.social-links li a:hover {
    background-color: transparent;
    border-color: #cd3c2e;
    color: #cd3c2e;
}
.social-links li a i {
    line-height: 44px;
}
.social-links.large li a i {
    line-height: 60px;
}
.header-top .social-links {
    margin: 0;
}
.header-top .social-links li {
    padding: 3px 5px 2px;
    border-right: 1px solid #e3e3e3;
    margin: 0;
}
.header-top .social-links li:hover {
    background-color: #fafafa;
}
.header-top .social-links li a {
    font-size: 16px;
}
.header-top .social-links li:last-child {
    border-right: none;
}
.header-top .social-links li a {
    border: none;
    width: 25px;
    height: 30px;
    color: #999999;
    background-color: transparent;
    border-color: transparent;
}
.header-top .social-links li a:hover {
    color: #cd3c2e;
}
.header-top .social-links li a i {
    line-height: 30px;
}
.footer-content .social-links {
    margin: 20px 0;
}
.footer-content .social-links li {
    margin: 0 5px 5px 0;
}
.footer-content .social-links li a {
    color: $social-links-text;
    background-color: transparent;
    border-color: $social-links-border;
}
.footer-content .social-links li a:hover {
    color: $social-links-text-hover;
    background-color: $social-links-border;
    border-color: $social-links-border;
}
.subfooter .social-links {
    margin: 0;
    text-align: right;
}
@each $social-link, $social-color in $social-links {
.social-links li.#{$social-link} a:hover,
.colored.social-links li.#{$social-link} a {
        background-color: $social-color;
        border-color: $social-color;
        color: $social-links-text-hover;
    }
}

.social-links .dropdown>button {
    padding: 6px 15px 5px;
    font-size: 16px;
    color: #999;
    text-align: center;
    min-width: 0;
    margin: 0;
    background-color: transparent;
    -moz-box-shadow: none !important;
    -o-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.social-links .dropdown>button i {
    width: 15px;
}
.social-links .dropdown.open>button>i:before {
    content: "\f00d";
    color: $red;
}
.social-links .dropdown-menu {
    z-index: 103;
    padding: 0px;
    min-width: 290px;
    margin-top: 1px;
    font-size: 0;
    box-shadow: none;
    background-color: #fafafa;
}
.social-links .dropdown-menu li a,
.social-links .dropdown-menu li a i {
    padding: 0;
}

/* Header top dropdowns
---------------------------------- */
.header-top-dropdown {
    float: right;
    font-size: 0;
    text-align: right;
}
.header-top-dropdown .dropdown>button {
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
    border-top: 0;
    border-bottom: 0;
}
.dark .header-top-dropdown .dropdown>button {
    border-right: 1px solid $header-top-border;
}

.header-top-dropdown .dropdown:last-child>button {
    border-right-color: transparent;
}
.header-top-dropdown .dropdown>button {
    padding: 8px 10px;
    margin: 0;
    font-size: 12px;
    color: $header-top-text;
    text-align: center;
    min-width: 0;
    background-color: transparent;
    -moz-box-shadow: none !important;
    -o-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    text-transform: none;
}
.dark.header-top .header-top-dropdown .dropdown>button {
    border-right: 1px solid $header-top-border;
}
.dark.header-top .header-top-dropdown .dropdown:last-child>button {
    border-right-color: transparent;
}
.header-top-dropdown .dropdown>button i {
    padding-right: 5px;
    width: 15px;
}

.header-top .header-top-dropdown .dropdown.open>button,
.header-top .header-top-dropdown .dropdown>button:hover {
    color: $header-top-text-hover;
    background-color: $header-top-bg-hover;
    border-right-color: $header-top-border;
}
.header-top-dropdown .dropdown:first-child.open>button,
.header-top-dropdown .dropdown:first-child>button:hover {
    border-left-color: $header-top-border;
}
.dark.header-top .header-top-dropdown .dropdown:first-child.open>button,
.dark.header-top .header-top-dropdown .dropdown:first-child>button:hover {
    border-left-color: $header-top-border;
}
.header .header-top-dropdown .dropdown>button {
    padding: 3px 10px;
}
.header .header-top-dropdown .dropdown.open>button,
.header .header-top-dropdown .dropdown>button:hover {
    background-color: transparent;
}
.header .header-top-dropdown .dropdown:first-child.open>button,
.header .header-top-dropdown .dropdown:first-child>button:hover {
    border-left-color: transparent;
}
.dark.header-top .header-top-dropdown .dropdown:last-child.open>button,
.dark.header-top .header-top-dropdown .dropdown:last-child>button:hover {
    border-right-color: $header-top-border;
}
.header .header-top-dropdown .dropdown-menu {
    margin-top: 27px;
}
.header-top-dropdown .dropdown-menu {
    z-index: 103;
    padding: 20px;
    min-width: 280px;
    margin-top: 0px;
}
.header-top-dropdown .search-box .form-group {
    margin-bottom: 0;
}
.header-top-dropdown form {
    margin: 0;
}
.header-top-dropdown .login-form span {
    margin: 0 3px;
}
.header-top-dropdown .login-form ul {
    padding-left: 0;
    list-style-position: inside;
    padding: 5px 0;
}
.header-top-dropdown .login-form .social-links {
    display: inline-block;
    margin: 0;
}
.dark .header-top-dropdown .login-form a {
    color: #cdcdcd;
    text-decoration: underline;
}
.dark .header-top-dropdown .login-form a:hover {
    text-decoration: none;
}
.header .header-top-dropdown {
    margin-top: 19px;
}
.dark.header .header-top-dropdown {
    color: #cdcdcd;
}
.fixed-header-on .header .header-top-dropdown {
    margin-top: 13px;
}
.fixed-header-on .header .header-top-dropdown .dropdown-menu {
    margin-top: 19px;
}

/* Small devices (tablets, 480px and below) */
@media (max-width: 480px) {
    .header-top-dropdown .btn-group {
        position: static;
    }
    .header-top-dropdown .dropdown-menu {
        right: 15px!important;
        width: 100%;
    }
}

/* Header forms (search) */
.dark .header-top-dropdown .form-control {
    border-radius: 0;
    background: #ffffff;
    height: 40px;
    padding-right: 30px;
    background-color: rgba(255, 255, 255, 0.5);
    border-color: $header-top-border;
    color: $text-dark;
    position: relative;
    -webkit-appearance: none;
    -webkit-box-shadow: none;
    box-shadow: none;    
}
.dark .header-top-dropdown .form-control:focus {
    background-color: rgba(255, 255, 255, 0.6);	
    -webkit-box-shadow: none;
    box-shadow: none;      
}
.dark .header-top-dropdown form i {
    color: $header-top-text;
}
.form-control-feedback {
    width: 40px;
    height: 40px;
    line-height: 42px !important;
    top: 0;
}

/* Scroll to top
---------------------------------- */
.scrollToTop {
    -webkit-border-radius: 20%;
    -moz-border-radius: 20%;
    border-radius: 20%;
    color: $scrolltop-text;
    position: fixed;
    bottom: 5px;
    right: 5px;
    width: 50px;
    height: 50px;
    text-align: center;
    cursor: pointer;
    background-color: $scrolltop-bg;
    z-index: 1005;
    display: none;
    border: 1px solid $scrolltop-text;
}
.scrollToTop i {
    line-height: 45px;
    font-size: 28px;
}

/* Small devices (tablets, phones less than 767px) */
@media (max-width:767px) {
    .scrollToTop {
        width: 40px;
        height: 40px;
        background-color: rgba($scrolltop-bg, 0.4);
    }
    .scrollToTop i {
        line-height: 36px;
        font-size: 20px;
    }
}


/* 9 Owl carousel
----------------------------------------------------------------------------- */
.owl-carousel {
	z-index: 4;
}
.owl-carousel .owl-item>div {
	margin: 10px 0 20px;
}
.owl-carousel.carousel .owl-item>div,
.owl-carousel.carousel-autoplay .owl-item>div {
	margin: 10px 20px 20px 0;
}
.owl-carousel.clients .owl-item>div {
	margin: 0px 30px 0px 0;
}
.well.owl-carousel .owl-item>div {
	margin: 10px 0 0;
}
.tab-content .owl-carousel .owl-item>div {
	margin: 0 0 20px;
}

/* 8 Main Slideshow
----------------------------------------------------------------------------- */
/* Slider Revolution
---------------------------------- */
.slider-banner-container {
	width: 100%;
	position: relative;
	padding: 0;
}
.slider-banner,
.slider-banner-2,
.slider-banner-3,
.slider-banner-fullscreen {
	width: 100%;
	position: relative;
}
.slider-banner>ul,
.slider-banner-2>ul,
.slider-banner-3>ul,
.slider-banner-fullscreen>ul {
	display: none;
}
.tp-loader.spinner0 {
	background: url("../images/loader.gif") no-repeat center center;
	box-shadow: none;
	-webkit-box-shadow: none;
	-webkit-animation: none;
	animation: none;
	border-radius: 0px;
	-moz-border-radius: 0px;
	-webkit-border-radius: 0px;
	width: 44px;
	height: 44px;
	margin-top: -22px;
	margin-left: -22px;
}
.tp-loader.spinner4 .dot1,
.tp-loader.spinner4 .dot2 {
	background-color: #666666;
	box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.80);
	-webkit-box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.80);
}
.slideshow-boxed {
	padding: 20px 0 60px;
}
.slideshow-boxed .slider-banner-container {
	padding: 10px;
	background: #fff;
	border: 1px solid #eaeaea;
}
.slideshow-boxed .tp-caption {
	margin-left: 20px !important;
}

/* Slider Revolution captions */
.tp-caption {
	z-index: 5;
}
.tp-caption a.btn,
.tp-caption a.btn:hover {
	color: #ffffff;
}
.default_bg {
	background-color: #e84c3d;
	color: #fff;
}
.white_bg {
	background-color: #ffffff;
	color: #333333;
}
.dark_gray_bg {
	background-color: #666666;
	color: #fff;
}
.light_gray_bg {
	background-color: #fafafa;
	color: #333;
	border: 1px solid #f3f3f3!important;
	border-left: none!important;
}
.tp-caption.small_thin_white {
	font-size: 18px;
	line-height: 26px;
	color: #ffffff;
	font-weight: 300;
	text-shadow: 1px 1px 1px #000000;
}
.tp-caption.small_thin_dark {
	font-size: 18px;
	line-height: 26px;
	color: #000;
	font-weight: 400;
}
.tp-caption.large {
	font-size: 36px;
	padding: 0px 15px;
}
.tp-caption.medium {
	font-size: 22px;
	padding: 2px 10px;
}
.tp-caption.small {
	font-size: 14px;
	padding: 5px;
}
.tp-caption.black,
.tp-caption .btn.black,
.tp-caption .btn.black:hover {
	color: #333333;
}
/* Large devices (Large desktops less than 1199px) */
@media (max-width:1199px) {
	.tp-caption {
		margin-left: 20px !important;
	}
}

/* Revolution Nav Controls */
.tparrows.round {
	background-image: none;
	width: 50px;
	height: 50px;
	text-align: center;
	color: #fff;
	background-color: rgba(0, 0, 0, 0.5);
	top: 50% !important;
	-webkit-transition: all linear 0.2s;
	-moz-transition: all linear 0.2s;
	-o-transition: all linear 0.2s;
	-ms-transition: all linear 0.2s;
	transition: all linear 0.2s;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	border-radius: 100%;
}
.tparrows.round:before {
	font-family: 'fontello';
	font-size: 24px;
	content: '\e87c';
	line-height: 52px;
	display: inline-block;
}
.tparrows.round.tp-rightarrow:before {
	content: '\e87d';
}
.tparrows.round:hover {
	color: #ffffff;
	background-color: #e84c3d;
}
.slideshow-boxed .slider-banner-container .tparrows {
	opacity: 0;
	filter: alpha(opacity=0);
}
.slideshow-boxed .slider-banner-container:hover .tparrows {
	opacity: 1;
	filter: alpha(opacity=100);
}
.tp-bullets {
	z-index: 100;
}
.tp-bullets.simplebullets.round .bullet {
	background-image: none;
	width: 14px;
	height: 14px;
	background: rgba(0, 0, 0, 0.5);
	border: none;
	margin: 0 3px;
	-webkit-transition: all linear 0.2s;
	-moz-transition: all linear 0.2s;
	-o-transition: all linear 0.2s;
	-ms-transition: all linear 0.2s;
	transition: all linear 0.2s;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	border-radius: 100%;
}
.bullets-with-bg + .tp-bullets.preview4 {
	height: auto;
	background-color: rgba(255, 255, 255, 1);
	padding: 5px 15px;
	bottom: -6px !important;
	border-radius: 3px;
}
.tp-bullets.simplebullets.round .bullet:hover,
.tp-bullets.simplebullets.round .bullet.selected {
	background-color: #e84c3d;
}
.tp-bannertimer {
	background-image: none;
	background-color: rgba(232, 76, 61, 0.7);
	z-index: 100;
	display: none;
}
.slideshow-boxed .tp-bullets {
	bottom: -33px !important;
}
.slideshow-boxed .bullets-with-bg + .tp-bullets.preview4 {
	padding: 7px 15px 2px;
	bottom: -45px !important;
}
.slideshow-boxed .tp-rightarrow {
	right: 10px !important;
}
.slideshow-boxed .tp-leftarrow {
	left: 10px !important;
}
.slideshow-boxed .tp-bullets.hidebullets {
	opacity: 1 !important;
	filter: alpha(opacity=100) !important;
}
.tparrows.preview2 {
	background-color: rgba(0,0,0,0.3);
	color: #ffffff;
}
.tparrows.preview2:hover {
	background-color: rgba(0,0,0,0.5);
	color: #ffffff;
}
.tparrows.preview2:after {
	margin-top: -18px;
	left: 22px;
	color: #ffffff;
	font-family: "FontAwesome";
	content: "\f104";
	font-size: 36px;
	line-height: 1;
}
.tp-rightarrow.preview2:after {
	content: "\f105";
	left: auto;
	right: 22px;
}
.tparrows.preview2:hover:after {
	color: #ffffff;
}
.tparrows.preview2 .tp-arr-titleholder {
	color: #ffffff;
	font-size: 16px;
}

/* Medium devices (phones, tablets, laptops) */
@media (min-width: 768px) and (max-width: 979px) {
	.tparrows.preview2:after {
		margin-top: -13px;
		left: 15px;
		font-size: 24px;
	}
	.tp-rightarrow.preview2:after {
		left: auto;
		right: 15px;
	}
}

@media (max-width: 767px) { 
	.tparrows.preview2 {
		display: none;
	}
}

/* Extra small devices (phones, less than 480px) */
@media (max-width:480px) {
	.tparrows,
	.tp-bullets {
		display: none;
	}
}


/* owl controls */
.owl-controls {
	width: 100%;
	margin-bottom: 50px;
}
.owl-controls .owl-buttons div {
	display: inline-block;
	zoom: 1;
	padding: 3px 10px;
	font-size: 12px;
	position: absolute;
	width: 30px;
	height: 30px;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
.owl-prev {
	left: 0;
	background-color: #e84c3d;
	color: #f1f1f1;
}
.owl-next {
	left: 30px;
	background-color: #f1f1f1;
	color: #e84c3d;
}
.owl-prev:hover,
.owl-next:hover,
.gray-bg .owl-next:hover {
	background-color: #333333;
	color: #f1f1f1;
}
.gray-bg .owl-next {
	background-color: #ffffff;
}
.owl-controls .owl-buttons div:after {
	font-family: "fontello";
	font-size: 18px;
	line-height: 26px;
}
.owl-prev:after {
	content: "\e87c";
}
.owl-next:after {
	content: "\e87d";
}
.no-touch .owl-controls.clickable .owl-buttons div:hover {
	filter: Alpha(Opacity=100);
	opacity: 1;
	text-decoration: none;
}
.content-slider-with-controls {
	margin-bottom: 20px;
}
.content-slider-with-controls .owl-controls,
.content-slider-with-controls-autoplay .owl-controls {
	margin-bottom: 0;
}
.content-slider-with-controls .owl-buttons div,
.content-slider-with-controls-autoplay .owl-buttons div {
	top: 50%;
	margin-top: -15px;
	background-color: #e84c3d;
	color: #f1f1f1;
}
.content-slider-with-controls .owl-buttons div.owl-next,
.content-slider-with-controls-autoplay .owl-buttons div.owl-next {
	left: auto;
	right: 0;
}
.content-slider-with-controls .owl-prev:hover,
.content-slider-with-controls .owl-next:hover,
.content-slider-with-controls-autoplay .owl-prev:hover,
.content-slider-with-controls-autoplay .owl-next:hover {
	background-color: #f1f1f1;
	color: #e84c3d;
}
.content-slider-with-controls-bottom .owl-controls .owl-pagination {
	bottom: -30px;
}
.content-slider-with-controls-bottom .owl-controls .owl-page span {
	background: #acacac;
}
.owl-controls .owl-pagination {
	position: absolute;
	bottom: 25px;
	text-align: center;
	margin: 0 auto;
	display: block;
	width: 100%;
}
.owl-controls .owl-page {
	display: inline-block;
	zoom: 1;
}
.owl-controls .owl-page span {
	display: block;
	width: 12px;
	height: 12px;
	margin: 5px 4px;
	background: #ffffff;
}
.owl-controls .owl-page.active span,
.owl-controls.clickable .owl-page:hover span {
	background: #e84c3d;
}

/* 10 IE8 Support
----------------------------------------------------------------------------- */
.ie8 .tp-caption{
    line-height: 1.5!important;
}
.ie8 .overlay {
    background-color: rgb(241, 241, 241);
    display: none;
    opacity: 0.8!important;
    filter: alpha(opacity=80)!important;
}
.ie8 a.overlay i { 
    display: none;
}
.ie8 .overlay-container:hover a.overlay i,
.ie8 .overlay-container:hover .overlay { 
    display: block;
}