//
// Alkacon website SCSS.
// 
// Bootstrap modifications.
//
// Developed for Bootstrap v3.3.
//

/* A Bootstrap modifications.
----------------------------------------------------------------------------- */

// Text color adjustments.
// Use Alkacon colors instead of Bootstrap standard colors.

.text-muted {
  color: #777;
}
.text-primary {
  color: $color-primary;
}
a.text-primary:hover {
  color: lighten($color-primary, 20%);
}
.text-success {
  color: $color-success;
}
a.text-success:hover {
  color: lighten($color-success, 20%);
}
.text-info {
  color: $color-info;
}
a.text-info:hover {
  color: lighten($color-info, 20%);
}
.text-warning {
  color: $color-warning;
}
a.text-warning:hover {
  color: lighten($color-warning, 20%);
}
.text-danger {
  color: $color-danger;
}
a.text-danger:hover {
  color: lighten($color-danger, 20%);
}
code {
  color: $red;
  background-color: lighten($red, 55%);
}

// Alerts
.alert {
    margin: 20px 0;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    position: relative;
    padding-left: 70px;
    overflow: hidden;
    z-index: 1;
    border: none;
}
.alert:before {
    position: absolute;
    content: "";
    top: 0px;
    left: 0px;
    width: 50px;
    height: 101%;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 2;
}
.alert-success {
    color: darken($color-success, 20%);
    background-color: lighten($color-success, 40%);
}
.alert-info {
    color: $blue;
    background-color: $blue-lighter;
}
.alert-warning {
    color: darken($color-warning, 20%);
    background-color: lighten($color-warning, 30%);
}
.alert-danger {
    color: $red;
    background-color: $red-lighter;
}
.alert:after {
    font-family: "FontAwesome";
    position: absolute;
    top: 10px;
    left: 17px;
    color: $text-light;
    font-size: 20px;
    z-index: 3;
}
.alert.alert-success:after {
    content: "\f00c";
}
.alert.alert-info:after {
    content: "\f05a";
}
.alert.alert-warning:after {
    content: "\f071";
}
.alert.alert-danger:after {
    content: "\f00d";
}
.close {
    font-size: 20px;
    font-weight: normal;
    filter: alpha(opacity=40);
    opacity: 0.4;
}

