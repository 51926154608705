/*
 * Alkacon Website CSS - ORANGE theme
 */

// import the Apollo variables (colors etc.)
@import 'apollo-variables-idea';

// ADJUSTMENT OF COLORS FOR REAL opencms-days.org site:
$primary-colors:
(
 // name                         // bg-color        // text-color    // highlight-color
 (white,                         $white,            $text-dark,     $red-light),
 (#{""+$graey-spelling}-lighter, $grey-lighter,     $text-dark,     $grey-light),
 (#{""+$graey-spelling}-light,   $grey-light,       $text-dark,     $grey-lighter),
 (#{""+$graey-spelling},         $grey,             $text-light,    $grey-light),
 (#{""+$graey-spelling}-dark,    $grey-dark,        $text-light,    $grey),
// (#{""+$graey-spelling}-darker,  $grey-darker,      $text-light,    $grey-dark),
// (blue-light,                    $blue-light,       $text-light,    $blue),
 (blue,                          $blue,             $text-light,    $blue-light),
 (blue-dark,                     $blue-dark,        $text-light,    $blue),
// (blue-darker,                   $blue-darker,      $text-light,    $blue-dark),
 (cyan,                          $cyan,             $text-light,    $blue-cyan),
// (red-light,                     $red-light,        $text-light,    $red),
 (red,                           $red,              $text-light,    $red-light),
// (red-dark,                      $red-dark,         $text-light,    $red),
// (red-darker,                    $red-darker,       $text-light,    $red-dark),
 (orange-light,                  $orange-light,     $text-dark,     $orange),
 (orange,                        $orange,           $text-light,    $red-orange),
// (orange-dark,                   $orange-dark,      $text-light,    $orange),
// (orange-darker,                 $orange-darker,    $text-light,    $orange-dark),
// (blue-cyan,                     $blue-cyan,        $text-light,    $cyan),
); 

$secondary-colors:
(
 (blue-lighter, $blue-lighter, $text-dark,     $blue-light),
 (red-lighter,  $red-lighter,  $text-dark,     $red-light),
 (orange-lighter,$orange-lighter,$text-dark,   $orange-light),
// (violet,       $violet,       $text-light,    $red),
// (brown,        $brown,        $text-light,    $orange),
// (red-orange,   $red-orange,   $text-light,    $orange),
// (red-cyan,     $red-cyan,     $text-light,    $red),          
// (green,        $green,        $text-light,    $orange),          
// (sea,          $sea,          $text-light,    $red-orange),
// (yellow,       $yellow,       $text-light,    $red-orange),
); 

$all-colors: join($primary-colors, $secondary-colors);

$border-colors: 
(
// (#{""+$graey-spelling},         $grey,         $text-light,     $grey-light),
 (#{""+$graey-spelling}-light,   $grey-light,   $text-dark,     $grey-lighter), 
 (blue,         $blue,         $text-light,    $blue-light),
// (red,          $red,          $text-light,    $red-light),
// (cyan,         $cyan,         $text-light,    $blue-cyan),
 (orange,       $orange,       $text-light,    $red-orange),
);

// Spaces used for margins and paddings
$spacers:       (0, 1, 2, 3, 4, 5, 10, 15, 20, 25, 30, 40, 50, 75, 100) !default;


$main-theme: $orange;
$main-theme-additional: $blue;

// Header top colors
$header-top-bg: $orange;
$header-top-bg-hover: $orange-lighter;
$header-top-border: $orange-darker;

// Main navigation colors
$navmenu-activeitem-text: $orange;
$navmenu-bg: $blue-dark;
$navmenu-bg-hover: $blue; 
$navmenu-main-bg: $white;

// Footer colors
$footer-bg: $orange;
$subfooter-bg: $blue-dark;

// import the other files
@import 'imports';
