
// Navigation header
$header-top-text:          $text-light !default;
$header-top-text-hover:    $text-dark !default;

$navmenu-border:           $navmenu-bg !default;
$navmenu-border-sep:       $grey !default;
$navmenu-text:             $text-light !default;
$navmenu-lvl-1-text-hover: $white !default;

$footer-border-top:        $grey-light !default;
$footer-border-bottom:     $grey !default;
$footer-text:              $white !default;
$footer-link-text:         $white !default;
$footer-link-text-hover:   $white !default;
$subfooter-text:           $text-light !default;
$subfooter-divider:        $grey-light !default;

// Headlines and links
$headline-text:            darken($text-dark, 10%) !default;
$headline-linecolor:       $main-theme-additional !default;
$link-text:                $red !default;
$link-text-hover:          lighten($red, 10%) !default;

// Buttons
$btn-default:              $red !default;

// Fixed header 
$fixed-header-border:      $gray-dark !default;
$fixed-header-bg:          rgba($white, 0.85) !default;

// Social colors
$social-links-bg:          transparent !default;
$social-links-border:      $gray !default;
$social-links-text:        $text-dark !default;
$social-links-text-hover:  $white !default;

// Other colors
$border-color:      $gray-light   !default;
$scrolltop-bg:      $subfooter-bg !default;
$scrolltop-text:    $grey-light   !default;
$mark-bg:           $orange       !default;
$mark-text:         $white        !default;

// Fixed social colors
$color-facebook:    #3b5998;
$color-googleplus:  #dd4b39;
$color-youtube:     #b31217;
$color-twitter:     #55acee;
$color-linkedin:    #0976b4;
$color-github:      #777777;
$color-slideshare:  #e98325;
$color-xing:        #126567;
$color-rss:         #ee802f;

// Form colors
$form-default:      $gray !default;
$form-active:       $blue-light !default;
$form-hover:        $blue-light !default;
$form-disabled:     $gray !default;
$form-border:       $border-color !default;
$form-bg:           $gray-lighter !default;
$form-bg-head:      $gray-light !default;
$form-icons:        $gray !default;

// Map to generate the social icon styles with
$social-links:  (facebook, $color-facebook),  
                (googleplus, $color-googleplus), 
                (youtube, $color-youtube), 
                (twitter, $color-twitter), 
                (github, $color-github),
                (xing, $color-xing),
                (rss, $color-rss),
                (slideshare, $color-slideshare);

$ALERT:             #ff00ff;
$ALERT2:            #00ffff;

// bootstrap modifications
@import 'bootstrap-modifications';

// basic idea template styles
@import 'idea-styles';

// blog styles from idea template
// Currently unused
// @import 'idea-blog';

// sky forms
@import 'sky-forms';

// backgrounds
@import 'backgrounds';

// alkacon style additions
@import 'alkacon-additions';

// Apollo template dependencies
@import '../../../apollo-template/org.opencms.apollo.template/scss/container-markers.scss';
@import '../../../apollo-template/org.opencms.apollo.template/scss/apollo-additions.scss';
@import '../../../apollo-template/org.opencms.apollo.template/scss/apollo-effects.scss';
@import '../../../apollo-template/org.opencms.apollo.template/scss/apollo-button.scss';
@import '../../../apollo-template/org.opencms.apollo.template/scss/apollo-linksequence.scss';
@import '../../../apollo-template/org.opencms.apollo.template/scss/apollo-list.scss';
@import '../../../apollo-template/org.opencms.apollo.template/scss/apollo-section.scss';
@import '../../../apollo-template/org.opencms.apollo.template/scss/apollo-tabs.scss';
@import '../../../apollo-template/org.opencms.apollo.template/scss/apollo-tiles.scss';
@import '../../../apollo-template/org.opencms.apollo.template/scss/apollo-blog.scss';


@import '../../../apollo-template/org.opencms.apollo.template/scss/apollo-generics.scss';
