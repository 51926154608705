
// Base style used by all section macro formatters

.ap-sec,
.ap-section {

	margin-bottom: 20px;
}

.ap-iconbox {

	text-align: center;
	margin-bottom: 20px;
}

// Styles for image section variations
// Plain style
.ap-img {

	margin-bottom: 20px;

	.ap-img-pic {
	}
	
	a {
	
    	color: $text-dark;
    	
		&:hover, &:active, &:focus {
        	color: $text-dark;
        	text-decoration: none;
      	}
      	.ap-img-desc {
			color: $main-theme;
			&:hover, &:active, &:focus {
	        	text-decoration: underline;
	        }
		}
	}	
	
    .info {
        text-align: right;
        .copyright {
            font-size: 10px;
            color: $grey;
        }
    }

	// Image section style "Bold headline, centered"
    &.ap-img-v1 {
		.ap-img-title {
			text-align: center;
			font-weight: 700;
		}
		.ap-img-desc {
			margin-top: 5px;
		}
    }
}

.ap-img-txt {
	.ap-img-title {
		margin-top: 10px;
	}
	.ap-img-desc {
		margin-top: 5px;
	}			
}

a.ap-img-link {
	color: $text-dark;

	&:hover, &:active, &:focus {
		color: $text-dark;
		text-decoration: none;
	}
	.ap-img-desc {
		color: $main-theme;
		&:hover, &:active, &:focus {
			text-decoration: underline;
		}
	}
}

.ap-tile .ap-sec,
.ap-tile .ap-section,
.ap-tile .ap-sec .ap-img,
.ap-tile .ap-section .ap-img {

    margin: 0;
}

// Section as image link box

.ap-sec-imagebox {
    overflow: hidden;
    position: relative;
}

.ap-sec-imagebox .ap-sec-imagebox-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: 15px 10px 0;
    opacity: 0.7;
    padding: 4px 8px;
    z-index: 5;
}

.ap-sec-imagebox .ap-img .info .copyright {
    position: absolute;
    bottom: 10px;
    right: 2px;
}

.ap-sec-imagebox .ap-sec-imagebox-title {
    margin-right: 26px;
    text-transform: uppercase;
}

.ap-sec-imagebox .ap-sec-imagebox-icon {
    position: absolute;
    top: 2px;
    right: 10px;
    background-color: $white;
    padding: 0 3px 3px;
    border: 1px solid $grey;
    box-shadow: 0 3px 5px $grey-dark;
}

.ap-sec-imagebox .ap-sec-imagebox-text {
    margin-top: 20px;
}

.ap-sec-imagebox-title, .ap-sec-imagebox-icon, .ap-sec-imagebox-text, .ap-sec-imagebox .ap-img .info .copyright {
	color: $grey;
}

// thumbnails

a.thumbnail {
    padding: 0;
    border: none;
    margin-bottom: 15px;
}

.thumbnail-style { 
    margin-bottom: 20px; 
    padding: 0;
    &:hover{
        box-shadow: none;

        a.btn-more:hover {
            color: $white;
        }

        a.btn-more.no-hover-effect {
            color: $white;
            right: 0px;
        }

    }

    a.btn-more {
        z-index: 20;

        &.no-hover-effect {
            right: 0;
            bottom: 0;
        }
    }

	&.shadow-border{
		padding: 7px;
		
		&:hover{
			box-shadow: 0 0 8px #ddd; 
			-webkit-transition: box-shadow 0.2s ease-in-out;
			-moz-transition: box-shadow 0.2s ease-in-out;
			-o-transition: box-shadow 0.2s ease-in-out;
			transition: box-shadow 0.2s ease-in-out;
		}
	}

}

// thumbnail effects

.thumbnail-kenburn {
    position: relative;

    img {
        left: 10px;
        margin-left: -10px;
        position: relative;
       -webkit-transition: all 0.8s ease-in-out;
       -moz-transition: all 0.8s ease-in-out;
       -o-transition: all 0.8s ease-in-out;
       -ms-transition: all 0.8s ease-in-out;
       transition: all 0.8s ease-in-out;
    }
}

.thumbnail-kenburn:hover img {
   -webkit-transform: scale(1.2) rotate(2deg);
   -moz-transform: scale(1.2) rotate(2deg);
   -o-transform: scale(1.2) rotate(2deg);
   -ms-transform: scale(1.2) rotate(2deg);
   transform: scale(1.2) rotate(2deg);
}

// overriding .ap-img margin-bottom
.thumbnail-kenburn .ap-img-pic {
    overflow: hidden;
    margin-bottom: 0;
}

.overflow-hidden{
    overflow: hidden;
}

.overflow-fix{  
    // fix for images showing through masks with overflow-hidden during transition.
    // see http://stackoverflow.com/a/27935035
    position: relative;
    z-index: 10;
}