

/* Sky-Forms
// Basic bootstrap adjustments based on Unify template
------------------------------------*/
/**/
/* defaults */
/**/
.sky-form {
  box-shadow: none;
  border: 1px solid $form-border;
}

.sky-form header {
  color: inherit;
  display: block;
  font-size: 20px;
  background: $form-bg-head;
  font-weight: 400;
  padding: 8px 30px;
}

.sky-form fieldset {
  background: $form-bg;
}

.sky-form fieldset + fieldset {
  border-top: 1px solid $form-border;
}

.sky-form footer {
  background: $form-bg-head;
  padding: 15px 30px;
}

.sky-form a {
  color: inherit;
}

.sky-form .input input {
  height: 34px;
  padding: 6px 12px;
}

.sky-form .input input,
.sky-form .select select,
.sky-form .textarea textarea {
  border-width: 1px;
  font-size: 14px;
  color: $text-dark;
}

.sky-form .select select {
  height: 34px;
  padding: 6px 10px;
}

.sky-form .select-multiple select {
  height: auto;
}

/**/
/* file inputs */
/**/
.sky-form .input-file .button {
  height: 32px;
  top: 1px;
  right: 1px;
}

/**/
/* captcha inputs */
/**/
.sky-form .input-captcha img {
  position: absolute;
  top: 1px;
  right: 1px;
  border-left: 1px solid $ALERT;
}


/**/
/* normal state */
/**/
.sky-form .input input,
.sky-form .select select,
.sky-form .textarea textarea,
.sky-form .radio i,
.sky-form .checkbox i,
.sky-form .toggle i,
.sky-form .icon-append,
.sky-form .icon-prepend {
  border-color: $form-default;
}
.sky-form .toggle.state-disabled i:before {
  background-color: $form-default; 
}
.sky-form .toggle i:before {
  background-color: $form-active;  
}

.sky-form .btn, sky-form .footer .btn {
    float: left;
    margin-left: 0;
    margin-right: 10px;
}

.sky-form .button {
  background-color: $alkacon-red;  
}


/**/
/* toggles */
/**/
.sky-form .toggle {
    font-weight: normal;
}

.sky-form .toggle i {
  width: 54px;
  height: 21px;
  border-width: 1px;
}
.sky-form .toggle i:after {
  top: 3px;
}
.sky-form .toggle i:before {
  top: 5px;
  right: 6px;
}

/*(remove Bootstrap 'radio, checkbox' margin-top)
--------------------------------------------------*/
.radio, .checkbox {
  margin-top: 0;
}

/**/
/* radios and checkboxes */
/**/
.sky-form .radio i,
.sky-form .checkbox i {
  width: 17px;
  height: 17px;
  border-width: 1px;
}

.sky-form .checkbox input + i:after {
  top: 2px;
  left: 0;
  font: normal 10px FontAwesome;
}

/**/
/* selects */
/**/
.sky-form .select i {
  top: 14px;
  width: 9px;
  right: 13px;
  height: 6px;
}
// arrow up in select box
.sky-form .select i:before {
  top: -2px;
  border-bottom: 4px solid $form-default;
}
// arrow down in select box
.sky-form .select i:after {
  top: 4px;
  border-top: 4px solid $form-default;
}

/**/
/* icons */
/**/
.sky-form .icon-append,
.sky-form .icon-prepend {
  top: 1px;
  height: 32px;
  font-size: 14px;
  line-height: 33px;
  background: inherit;
}
.sky-form .icon-append {
  right: 1px;
  padding: 0 3px;
  min-width: 34px;
}

.sky-form .icon-prepend {
  left: 6px;
  padding-right: 5px;
}

/**/
/* hover state */
/**/
.sky-form .input:hover input,
.sky-form .select:hover select,
.sky-form .textarea:hover textarea {
    border-color: $form-hover;
    box-shadow: none;
}

.sky-form .radio:hover i,
.sky-form .checkbox:hover i,
.sky-form .toggle:hover i,
.sky-form .ui-slider-handle:hover {
  border-color: $form-hover;
  box-shadow: none;  
}
.sky-form .rating input + label:hover,
.sky-form .rating input + label:hover ~ label {
  color: $form-hover;
  box-shadow: none;  
}

.sky-form .icon-append,
.sky-form .icon-prepend {
  color: $form-icons;
}

/**/
/* checked state */
/**/
.sky-form .radio input + i:after {
  top: 5px;
  left: 5px;
  background-color: $form-active;  
}
.sky-form .checkbox input + i:after {
  color: $form-active;
}
.sky-form .radio input:checked + i,
.sky-form .checkbox input:checked + i,
.sky-form .toggle input:checked + i {
  border-color: $form-active;   
}
.sky-form .rating input:checked ~ label {
  color: $form-active; 
}

/**/
/* focus state */
/**/
.sky-form .input input:focus,
.sky-form .select select:focus,
.sky-form .textarea textarea:focus {
  border-color: $form-active;
  box-shadow: 0 0 2px $form-active;  
}

.sky-form .radio input:focus + i,
.sky-form .checkbox input:focus + i,
.sky-form .toggle input:focus + i {
  border-color: $form-active;
  box-shadow: 0 0 2px $form-active;  
}

/**/
/* disabled state */
/**/
.sky-form .input.state-disabled:hover input,
.sky-form .select.state-disabled:hover select,
.sky-form .textarea.state-disabled:hover textarea,
.sky-form .radio.state-disabled:hover i,
.sky-form .checkbox.state-disabled:hover i,
.sky-form .toggle.state-disabled:hover i {
  border-color: $form-disabled;
}

/**/
/* datepicker */
/**/
.ui-datepicker-header {
  padding: 10px 0;
  font-size: 16px;
}

.ui-datepicker-inline {
  border-width: 1px;
}

.ui-datepicker-inline .ui-datepicker-header {
  line-height: 27px;
}

.ui-datepicker-prev, 
.ui-datepicker-next {
  font-size: 18px;
  line-height: 47px;
}

.ui-datepicker-calendar a {
  border: 1px solid transparent;
}

.ui-datepicker-calendar .ui-state-active {
  background: inherit;
  border: 1px solid $ALERT;
}
.ui-datepicker-calendar a:hover {
  background: inherit;  
  border: 1px solid $ALERT;
}

/**/
/* bootstrap compatibility */
/**/
.sky-form button *,
.sky-form button *:after,
.sky-form button *:before {
  margin: 0 auto;
  padding: 0 auto;
}

.label {
  padding: 0.2em 0 0.3em;
}
