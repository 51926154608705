
// Import Apollo variables

$graey-spelling: gray;

$yellow:            #f7ee18;

@import '../../../apollo-template/org.opencms.apollo.template/scss/apollo-variables.scss';

/***
// Neon colors: currently unused, but saved in case we want this later

$neon-yellow:       #f3f315;
$neon-orange:       #ff9933;
$neon-green:        #c1fd33;  
$neon-pink:         #fc5ab8;
$neon-blue:         #0dd5fc;

$neon-colors:
(
 // name                         // bg-color        // text-color    // highlight-color
 (blue-cyan,                     $blue-cyan,        $text-light,    $cyan),
 (neon-yellow,                   $neon-yellow,      $text-light,    $neon-orange),
 (neon-green,                    $neon-green,       $text-light,    $neon-pink),
 (neon-orange,                   $neon-orange,      $text-light,    $neon-blue),
 (neon-pink,                     $neon-pink,        $text-light,    $neon-green),
 (neon-blue,                     $neon-blue,        $text-light,    $neon-yellow),
); 

// all colors combined
$all-colors: join($all-colors, $neon-colors);     
$colors:     $all-colors;

***/

// Override Apolle defaults for website template

$btn-text-transform: uppercase;
$btn-border-style:   1px solid lighten($red, 10%);
$btn-min-width:      160px;
$btn-padding:        10px;

$headline-bottom-bg: none;

$tab-text-transform: uppercase;