//
// Alkacon website SCSS.
// 
// Original styles.
//

/* Alkacon original styles.
----------------------------------------------------------------------------- */

/* Navigation menu adjustments */
.mega-menu .menu > li > a i.fa, .dropdown-menu > li > a i.fa {
  margin-left: 0.4em;
}

.dropdown-menu .divider {
	margin-bottom: 0;
}

// Special class for navbar on small devices (tablets, phones less than 767px) */
@media (min-width:768px) {
    .p-nav {
        padding: 0;
    }      
}

@media (max-width:767px) {    
    .p-nav {
        padding: 0;
    }      
    .navbar {
        border: none;
        min-height: 0;
    }
    .navbar-nav {
        margin: 1px -15px;
    }    
    .navbar-toggle {
        margin-right: 0;
        background: $white;
    } 
    .navbar-collapse {
        border: none;
    }
	.dropdown-menu .divider {
	    margin-top: 0;
	}    
	.header .logo{
		margin: 10px 0;
		overflow: hidden;
	}
}



.header .dropdown-menu .dropdown-menu {
    border-top-color: $navmenu-border-sep;
}

.dropdown-menu-right {
	left: auto;
}


//Image gallery animation
.comein {
 	transform: translateY(150px);
	animation: come-in 0.8s ease forwards;
	&:nth-child(odd) {
  		animation-duration: 0.6s; /* So they look staggered */
	}
}


@keyframes come-in {
  to { transform: translateY(0); }
}
.color-tile {
    margin-bottom: 30px;
    padding: 25px 25px;    
    text-align: center;
    border: 1px solid $border-color;
    -webkit-box-shadow: 2px 2px 2px rgba($black, .05);
    box-shadow: 2px 2px 2px rgba($black, .2);    
}

//spinner animation
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner{
	width: 25px;	
	height: auto;
	font-size: 25px;
	position: absolute;
	transform: translateY(-50%);
	&, a &  {
		color: $gray;
	}
	
	left: 50%;
	i{
		animation: spinner 1s infinite;
	}
}

.tv-m{
	justify-content: center;
	align-items: center;
	display: flex;	
	text-transform: none;
	font-weight: 300;
}

.tv-m-100{
	@extend .tv-m;
	height: 100px;
}

.isotope-item.line-left{	
	border-left: 2px solid $blue-light;
	.red-line{
		border-left: 2px solid $red !important;
	}
	.image-box{
		.tv-m{
			text-align: left;
			justify-content: initial;
		}	
	}
}


#gridOptions{
	.sort, .filters{
		transition: all 0.3s;
	}
}
// Anchor links hidden style to compensate the top menu
.anchor {
    display: block; 
    max-height: 0px;
    position: relative; 
    top: -200px;
    background-color: $red;   
}

@media (min-width: 768px) {
	.anchor {
	    display: block; 
	    max-height: 0px;
	    position: relative; 
	    top: -65px;
	    background-color: $red;   
	}
}

// Speaker name special style that either overlaps with image or not
.speakername {

    padding: 5px !important;
    margin-left: 10px;       
    margin-right: 10px;       
    position: relative;
    top: -10px
}

@media (max-width: 767px) {
    .speakername {
        font-size: 11px;
        padding-left: 0px;
        padding-right: 0px;
    }
}


.speakerimage2 {
    
}

@media (min-width: 992px) {
    .speakerimage2 {
	   position: absolute;
       z-index: 42;
       padding-right: 15px;         
	}
}


.ribbon-wrapper-green {
  width: 85px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: -3px;
  right: -3px;
}

.ribbon-green {
  z-index: 100;
  font: bold 15px Sans-Serif;
  color: $white;
  text-align: center;
  text-shadow: rgba(255,255,255,0.5) 0px 1px 0px;
  transform: rotate(45deg);
  position: relative;
  padding: 7px 0;
  left: -5px;
  top: 15px;
  width: 120px;
  background-color: $red-light;
  box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
}

.ribbon-green:before, .ribbon-green:after {
  content: "";
  border-top:   3px solid #6e8900;   
  border-left:  3px solid transparent;
  border-right: 3px solid transparent;
  position:absolute;
  bottom: -3px;
}

.ribbon-green:before {
  left: 0;
}
.ribbon-green:after {
  right: 0;
}

